import React from "react"
import { colors } from "../../../../theme"
import Button from "../../../UI/Button"
import Loader from "../../../UI/Loader"
import ProjectIdea from "./components/ProjectIdea"
import { Actions, ProjectIdeas, Wrapper } from "./index.styles"
import ProjectIdeasFeedState from "./useProjectIdeasFeed"

const ProjectIdeasFeed = props => {
  const { projectIdeas, loading, publishProjectIdea, rejectProjectIdea, publishAll, rejectAll } = ProjectIdeasFeedState.useContainer()

  if (loading) {
    return <Loader />
  }

  return (
    <Wrapper>
      <Actions>
        <Button bgColor={colors.green} onClick={publishAll}>Publish all</Button>
        <Button bgColor={colors.red} onClick={rejectAll}>Reject all</Button>
      </Actions>
      <ProjectIdeas>
        {projectIdeas.map(p => (
          <ProjectIdea
            key={p._id}
            {...p}
            publish={() => publishProjectIdea(p)}
            reject={() => rejectProjectIdea(p)}
          />
        ))}
      </ProjectIdeas>
      <Actions>
        <Button bgColor={colors.green} onClick={publishAll}>Publish all</Button>
        <Button bgColor={colors.red} onClick={rejectAll}>Reject all</Button>
      </Actions>
    </Wrapper>
  )
}

export default props => (
  <ProjectIdeasFeedState.Provider>
    <ProjectIdeasFeed {...props} />
  </ProjectIdeasFeedState.Provider>
)
