import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 15px;
`

export const Explanations = styled.div`
  margin-bottom: 20px;
`

export const Projects = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-gap: 15px;
  > * {
    margin-bottom: 20px;
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  > * {
    margin-left: 10px;
  }
`
