import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 15px;
`

export const ProjectIdeas = styled.div`
  > * {
    margin-bottom: 20px;
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  > * {
    margin-left: 10px;
  }
`

export const Disclaimer = styled.div`
  margin-bottom: 10px;
  font-style: italic;
`

