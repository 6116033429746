import React from "react"
import { colors } from "../../../../theme"
import Button from "../../../UI/Button"
import Loader from "../../../UI/Loader"
import Project from "./components/Project"
import { Actions, Projects, Wrapper, Explanations } from "./index.styles"
import ProjectsFeedState from "./useProjectsFeed"

const ProjectsFeed = props => {
  const {
    projects,
    hasMore,
    loading,
    publishProject,
    rejectProject,
    publishAll,
    publishAllAsPrivate,
    rejectAll,
    updateProject,
    updateTopicWithId
  } = ProjectsFeedState.useContainer()

  if (loading) {
    return <Loader />
  }
  return (
    <Wrapper>
      <Actions>
        <Button bgColor={colors.green} onClick={publishAllAsPrivate}>
          Publish all as Private
        </Button>
        <Button bgColor={colors.green} onClick={publishAll}>
          Publish all
        </Button>
        <Button bgColor={colors.red} onClick={rejectAll}>
          Reject all
        </Button>
      </Actions>
      <Explanations>
        <p>Making a project private using the padlock will still allow invited collaborators to see and contribute to the project.</p>
      </Explanations>
      <Projects>
        { !hasMore && projects.length === 0 && <div style={{ fontSize: '22px', textAlign: 'center' }}>
          The feed is empty! Come back later!  
        </div>}
        {projects.map(p => (
          <Project
            key={p._id}
            {...p}
            updateProject={(payload) => updateProject(p, payload)}
            updateTopicWithId={(topicId, payload) => updateTopicWithId(p, topicId, payload)}
            publish={() => publishProject(p)}
            reject={() => rejectProject(p)}
          />
        ))}
      </Projects>
      <Actions>
        <Button bgColor={colors.green} onClick={publishAllAsPrivate}>
          Publish all as Private
        </Button>
        <Button bgColor={colors.green} onClick={publishAll}>
          Publish all
        </Button>
        <Button bgColor={colors.red} onClick={rejectAll}>
          Reject all
        </Button>
      </Actions>
    </Wrapper>
  )
}

export default props => (
  <ProjectsFeedState.Provider>
    <ProjectsFeed {...props} />
  </ProjectsFeedState.Provider>
)
