import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { useApolloClient } from '@apollo/client'
import GET_PROJECTS from './getProjects.graphql'
import UPDATE_PROJECT from './updateProject.graphql'

const LIMIT_PER_PAGE = 1

const useProjectsFeedState = () => {
  const [projects, setProjects] = useState([])
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [canPublish, setCanPublish] = useState(true)
  const [loading, setLoading] = useState(false)

  const client = useApolloClient()

  const fetch = async (page) => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_PROJECTS,
      variables: {
        skip: (page || 0) * LIMIT_PER_PAGE,
        limit: LIMIT_PER_PAGE
      },
      fetchPolicy: 'network-only'
    })
    if (!data) return
    setPage(page => page + 1)
    if (data.projects.length < LIMIT_PER_PAGE) {
      setHasMore(false)
    }
    setProjects([...projects, ...data.projects])
    setLoading(false)
  }

  useEffect(() => {
    if (projects.length === 0 && hasMore) {
      fetch()
    }
  }, [projects, hasMore])

  const publishAll = async () => {
    if (!canPublish) return
    setCanPublish(false)
    await Promise.all(projects.map((p) => publishProject(p)))
    setCanPublish(true)
  }

  const publishAllAsPrivate = async () => {
    if (!canPublish) return
    setCanPublish(false)
    await Promise.all(projects.map((p) => publishProject(p, { isPrivate: true })))
    setCanPublish(true)
  }

  const rejectAll = async () => {
    if (!canPublish) return
    setCanPublish(false)
    await Promise.all(projects.map((p) => rejectProject(p)))
    setCanPublish(true)
  }

  const updateProject = async (project, payload) => {
    const { data } = await client.mutate({
      mutation: UPDATE_PROJECT,
      variables: {
        _id: project._id,
        input: { ...payload }
      }
    })
    if (data.updateProject) {
      setProjects(projects => projects.map(p => p._id !== project._id ? p : { ...p, ...payload }))
    }
  }

  const updateTopicWithId = async (project, topicId, payload) => {
    let topics = [...project.projects]
      .map(t => t.id === topicId ? { ...t, ...payload } : t)
    updateProject(project, { projects: topics })
  }

  const rejectProject = async (project) => {
    const { data } = await client.mutate({
      mutation: UPDATE_PROJECT,
      variables: {
        _id: project._id,
        input: { status: 'rejected' }
      }
    })
    if (data.updateProject) {
      setProjects(projects => projects.filter(p => p._id !== project._id))
    }
  }

  const publishProject = async (project, extras = {}) => {
    const { data } = await client.mutate({
      mutation: UPDATE_PROJECT,
      variables: {
        _id: project._id,
        input: { status: 'published', ...extras }
      }
    })
    if (data.updateProject) {
      setProjects(projects => projects.filter(p => p._id !== project._id))
    }
  }

  return { projects, loading, fetch, hasMore, page, publishProject, publishAll, publishAllAsPrivate, rejectProject, rejectAll, updateProject, updateTopicWithId }
}

export default createContainer(useProjectsFeedState)
