import styled, { css } from 'styled-components'
import { colors } from '../../../theme'

export const Wrapper = styled.div`

`

export const Nav = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
`

export const Item = styled.div`
  padding: 10px 10px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: ${colors.indigo};
  font-weight: bold;
  cursor: pointer;
  ${props => props.selected && css`
    background-color: #4661CC11;
    border-bottom: 2px solid ${colors.indigo};
  `}
`

export const Content = styled.div`

`
