import React from "react"

import ProjectsFeed from "../components/ProjectsFeed";

const ProjectsPage = (props) => {
  if (typeof window === "undefined") return null

  return <ProjectsFeed {...props} />
}

export default ProjectsPage
