import React from "react"
import styled from "styled-components"
import Tabs from "../UI/Tabs"
import ProjectIdeasFeed from "./components/ProjectIdeasFeed"
import ProjectsFeed from "./components/ProjectsFeed"

const Wrapper = styled.div`
  margin: -21px -25px;
`

const Projects = props => {
  return (
    <Wrapper>
      <Tabs
        path={props.uri}
        tabs={[
          {
            title: "Projects",
            child: <ProjectsFeed />,
          },
          {
            title: "Ideas",
            child: <ProjectIdeasFeed />,
          },
        ]}
      />
    </Wrapper>
  )
}

export default Projects
