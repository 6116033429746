import { useEffect, useState } from "react";
import { useApolloClient } from '@apollo/client'
import GET_PROJECT_IDEAS from './getProjectIdeas.graphql'
import { createContainer } from "unstated-next";
import UPDATE_PROJECT_IDEA from './updateProjectIdea.graphql'

const LIMIT_PER_PAGE = 100

const useProjectIdeasFeedState = () => {
  const [projectIdeas, setProjectIdeas] = useState([])
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [canPublish, setCanPublish] = useState(true)
  const [loading, setLoading] = useState(false)

  const client = useApolloClient()

  useEffect(() => {
    fetch()
    // eslint-disable-next-line
  }, [])

  const fetch = async (page) => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_PROJECT_IDEAS,
      variables: {
        skip: (page || 0) * LIMIT_PER_PAGE,
        limit: LIMIT_PER_PAGE
      },
      fetchPolicy: 'network-only'
    })
    if (!data) return
    setPage(page => page + 1)
    if (data.tutoring.projectIdeas.length < LIMIT_PER_PAGE) {
      setHasMore(false)
    }
    setProjectIdeas([...projectIdeas, ...data.tutoring.projectIdeas])
    setLoading(false)
  }

  const publishAll = async () => {
    if (!canPublish) return
    setCanPublish(false)
    await Promise.all(projectIdeas.map((p) => publishProjectIdea(p)))
    setCanPublish(true)
  }

  const rejectAll = async () => {
    if (!canPublish) return
    setCanPublish(false)
    await Promise.all(projectIdeas.map((p) => rejectProjectIdea(p)))
    setCanPublish(true)
  }

  const rejectProjectIdea = async (projectIdea) => {
    const { data } = await client.mutate({
      mutation: UPDATE_PROJECT_IDEA,
      variables: {
        _id: projectIdea._id,
        input: { status: 'rejected' }
      }
    })
    if (data.updateProjectIdea) {
      setProjectIdeas(projectIdeas => projectIdeas.filter(p => p._id !== projectIdea._id))
    }
  }

  const publishProjectIdea = async (projectIdea) => {
    const { data } = await client.mutate({
      mutation: UPDATE_PROJECT_IDEA,
      variables: {
        _id: projectIdea._id,
        input: { status: 'published' }
      }
    })
    if (data.updateProjectIdea) {
      setProjectIdeas(projectIdeas => projectIdeas.filter(p => p._id !== projectIdea._id))
    }
  }

  return { loading, projectIdeas, fetch, hasMore, page, publishProjectIdea, publishAll, rejectProjectIdea, rejectAll }
}

export default createContainer(useProjectIdeasFeedState)
