import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Avatar from '../../../../../UI/Avatar'
import styled, { css } from 'styled-components'
import Button from '../../../../../UI/Button'
import { colors } from '../../../../../../theme'
import { Link, navigate } from 'gatsby'

const Wrapper = styled.div`
  background-color: #fff;
  padding: 12px;
  border-radius: 9px;
`

const Header = styled.div`
  > div {
    display: flex;
    align-items: center;
  }
  margin-bottom: 15px;
`

const Author = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 10px;
  }
  margin-bottom: 8px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  > :first-child {
    margin-right: 10px;
  }
`

const Actions = styled.div`
  margin-top: 10px;
  display: flex;
  > * {
    margin-right: 8px;
  }
`

const Content = styled.div`
  > * {
    margin-bottom: 12px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

// const Project = styled.div`
//   display: block !important;
//   > :first-child {
//     font-weight: bold;
//     color: ${colors.indigo};
//   }
//   > :nth-child(3) {
//     font-style: italic;
//   }
//   margin-bottom: 6px;
// `
// 
// const SubProject = styled.div`
//   display: block !important;
//   > :first-child {
//     font-weight: bold;
//     color: ${colors.indigo};
//   }
//   > :nth-child(3) {
//     font-style: italic;
//   }
//   margin-bottom: 8px;
// `

const Drawings = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`

const DrawingItem = styled.div`
  height: 120px;
  width: 120px;
  background-image: url(${props => props.artwork});
  background-size: 90%;
  background-repeat: no-repeat;

  background-position: center;
  border: 4px solid #000;
  ${props => props.status === 'published' && css`
    border-color: ${colors.green};
  `}
  ${props => props.status === 'pending' && css`
    border-color: ${colors.orange};
  `}
  ${props => props.status === 'rejected' && css`
    border-color: ${colors.red};
  `}
`

const WritingItem = styled.li`
  ${props => props.status === 'published' && css`
    color: ${colors.green};
  `}
  ${props => props.status === 'pending' && css`
    color: ${colors.orange};
  `}
  ${props => props.status === 'rejected' && css`
    color: ${colors.red};
  `}

  a {
    text-decoration: underline;
    color: inherit;
  }
`
  

const ProjectIdea = (props) => {
  const { elements } = props

  const [writings, drawings] = useMemo(() => elements.reduce((acc, elem) => {
    if (elem.writing) { return [[...acc[0], elem.writing], [...acc[1]]] }
    if (elem.drawing) { return [[...acc[0]], [...acc[1], elem.drawing]] }
    return acc
  }, [[], []]), [elements])

  return <Wrapper>
    <Header>
      <div>
        <Author>
          <Avatar user={props.user} /> 
          <div>{props.user.username}</div>
        </Author>
      </div>
      { /* props.project && <Project>
        <div>Project</div>
        <div>{props.project.name}</div>
        <div>{props.project.description}</div>
      </Project> */ }
      { /* props.subProject && <SubProject>
        <div>Topic</div>
        <div>{props.subProject.name}</div>
        <div>{props.subProject.description}</div>
      </SubProject> */ }
      <Title>
        <div>{props.title}</div>
      </Title>
    </Header>
    { props.elements && <Content>
      <h4>Writing</h4>
        <ul>
          {
            writings.map(w => <WritingItem key={w._id} status={w.status}>
              <a target='__blank' href={`/writing/${w._id}`}>{w.title}</a>
            </WritingItem>)
          }
        </ul>

        <h4>Drawings</h4>
        <Drawings>
          {
            drawings.map(d => <DrawingItem artwork={d.artwork.url} key={d._id} status={d.status} />)
          }
        </Drawings>
    </Content> }
    <Actions>
      { props.publish && <Button onClick={props.publish} bgColor={colors.green}>Publish</Button> }
      { props.reject && <Button onClick={props.reject} bgColor={colors.red}>Reject</Button> }
    </Actions>
  </Wrapper>
}

ProjectIdea.propTypes = {
  _id: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.shape({
    _id: PropTypes.string,
    username: PropTypes.string,
    avatar: PropTypes.shape({
      url: PropTypes.string,
    })
  }),
  project: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string
  }),
  subProject: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string
  }),
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      writing: PropTypes.shape({
        _id: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string
      }),
      drawing: PropTypes.shape({
        _id: PropTypes.string,
        artwork: PropTypes.shape({
          url: PropTypes.string
        })
      })
    })
  ),
  publish: PropTypes.func,
  reject: PropTypes.func
}

ProjectIdea.defaultProps = {
  user: {}
}

export default ProjectIdea
