import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as styles from './index.styles'
import shortid from 'shortid'
import { navigate } from 'gatsby'
import { useRouter } from '../../../providers/RouterProvider'

const { Wrapper, Nav, Item, Content } = styles

const Tabs = (props) => {
  const [selectedIndex, selectIndex] = useState(null)
  const { queryParams, location } = useRouter()
  const { tabs } = props

  useEffect(() => {
    if (queryParams.get('tab')) {
      selectIndex(parseInt(queryParams.get('tab')))
    } else {
      setIndex(0)
    }
    // eslint-disable-next-line
  }, [location.search])

  const setIndex = (i) => {
    queryParams.set('tab', i)
    navigate(`${props.path}?${queryParams.toString()}`)
  }

  if (!props.tabs || props.tabs.length === 0) return null
  return <Wrapper>
    <Nav basePath={props.path}>
      {
        tabs.map((t, i) =>
          <Item
            key={shortid.generate()}
            selected={selectedIndex === i}
            onClick={() => setIndex(i)}>
              {t.title}
          </Item>)
      }
    </Nav>
    <Content>
    { selectedIndex !== null && props.tabs[selectedIndex].child }
    </Content>
  </Wrapper>
}

Tabs.propTypes = {
  path: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      child: PropTypes.node
    })
  )
}

Tabs.defaultProps = {

}

export default Tabs
