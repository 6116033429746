import { TextInput } from 'grommet'
import { FormClose, FormEdit, Save } from 'grommet-icons'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'

const Wrapper = styled.div`
  position: relative;
  min-height: 35px;
  padding-right: 45px;
`

const ActionButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(-0, -50%);
  right: ${props => props.padded ? '-60px' : '-30px'};
  background-color: #fff;
  border-radius: 4px;
  width: 30px;
  height: 30px;
`

const EditableText = ({ children, onSave }) => {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(children)

  useEffect(() => {
    setValue(children)
  }, [children])

  const onSaveAction = async () => {
    try {
      await onSave(value)
      setEditing(false)
    } catch (err) {
      setValue(children)
    }
  }

  const onCancelAction = () => {
    setEditing(false)
    setValue(children)
  }

  if (editing) return <Wrapper>
    <TextInput value={value} onChange={e => setValue(e.target.value)} />
    <ActionButton onClick={onSaveAction}><Save color={colors.green} /></ActionButton>
    <ActionButton padded onClick={onCancelAction}><FormClose /></ActionButton>
  </Wrapper>
  
  return <Wrapper onClick={() => setEditing(true)}>
    <span>{ value }</span>
    <ActionButton><FormEdit color={colors.indigo} /></ActionButton>  
  </Wrapper>
}

EditableText.propTypes = {
  children: PropTypes.string,
  onSave: PropTypes.func
}

EditableText.defaultProps = {
  onSave: (value) => {}
}

export default EditableText
